const dentlabRoutes = [
    {
        path:'list',
        name:'dentlab-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentlab/list.vue')
    },
    {
        path:'info',
        name:'dentlab-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentlab/info.vue')
    },
    

]
export default dentlabRoutes