<template>
    <nav class="fixed-top">
        <div class="nav-bar navBar-1 p-2 pl-4">
            <img class="logo mr-3" src="/images/single-logo-whita.png">
            <h4 class="mb-0">醫彼牙數位下單媒合平台</h4>
            <div class="align-middle">
                <ul>
                    <li>
                        <i class="mt-auto mb-auto fas fs-30 fa-user-circle"></i>
                    </li>
                    <li>
                        {{navData.user_name}}
                    </li>
                    <li>
                        @
                    </li>
                    <li>
                        {{navData.clinci_name}}
                    </li>
                    <li>
                        <i class="mt-auto mb-auto fas fs-22 fa-sign-out-alt" @click="clickLogout"></i>
                    </li>
                </ul>
            </div>
        </div>
        <div class="nav-bar navBar-2">
            <div class="manage-name p-2 pt-3  pl-4">
                <h4>{{navData.title}}</h4>
            </div>
            <div class="sub-title p-2 pt-3  pl-4">
                <h4 class="">
                    <router-link class="router-link" v-if="navData.isBack ==true" to>
                        <i class="mr-2 fas fa-fw fa-arrow-left" @click="$router.back(-1)"></i>
                    </router-link>
                    <router-link class="router-link" v-if="navData.isLink" to>
                        <i class="mr-2 fas fa-fw fa-arrow-left" @click="$router.push({ path: navData.isLink })"></i>
                    </router-link>
                    <span>{{navData.sub_tile}}</span>
                </h4>
            </div>
            <div v-if="navData.acvtion.length > 0" class="main-function p-2 pt-3  pl-4">
                <ul>
                    <li v-for="items  in navData.acvtion" :key="items">
                        <router-link class="router-link" :to="items.to">
                            <i class="mr-2 fas fa-fw fs-15" v-bind:class="[items.icon]"></i><span>{{items.name}}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        
    </nav>
</template>
<script>
    export default {
        name: 'navTopView',
        props:[
            "navData"
        ],
        methods: {
            clickLogout(){
                this.$store.commit('userLogOut')
                document.location.href = process.env.VUE_APP_MAIN_URL
                // document.location.href = 'http://localhost:3005'
            }
        },
    }
</script>
<style scoped>

.navBar-1 > div{
    margin-left: auto;
    margin-right: 0px;
}
.navBar-1 > div > ul{
    display: flex;
    margin-right: 12px;
    align-items: center;
    margin-bottom: 0px;
}
.navBar-1 > div > ul > li:nth-child(1){
    margin-right: 21px;
}
.navBar-1 > div > ul > li:nth-child(2){
    margin-right: 10px;
}
.navBar-1 > div > ul > li:nth-child(3){
    margin-right: 15px;
}
.navBar-1 > div > ul > li:nth-child(4){
    margin-right: 21px;
}
.fa-sign-out-alt{
    cursor: pointer;
}
.nav-bar > .logo{
    height: 30px;
}
</style>