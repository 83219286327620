import $ from "jquery"
import modals from "bootstrap/js/dist/modal"
import CryptoJS from "crypto-js";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { getStorage, ref} from "firebase/storage";
const firebaseConfig = {
    apiKey: "AIzaSyCRfGnfcDs7tBvesdDvsRJ4L8SXyBFq5z8",
    authDomain: "dcp-test-186fb.firebaseapp.com",
    projectId: "dcp-test-186fb",
    storageBucket: "dcp-test-186fb.appspot.com",
    messagingSenderId: "158257781334",
    appId: "1:158257781334:web:5c8499ba30688ee17e39f7",
    measurementId: "G-2KVHDBJJMT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
console.log('storage->',storage) //gs://dcp-test-186fb.appspot.com/
const storageRef = ref(storage, 'some-child');
console.log('storageRef->',storageRef)
class shareds {
    //物件建立時產生
    constructor(){
    }

    //設定subtitle文字內容
    setText(data={}){
        $(data.tag).text(data.text)
    }
    //處理GCP檔案上傳
    uploadFileToGCPHandle(parameter,file){ //處理上傳檔案作業
        const metadata = {
            contentType: file.type
        };
        let url = 'DCP-dev/'+parameter.path
        const storageRef = ref(storage, url + parameter.filename);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                if ( parameter.progress ){
                    parameter.progress(progress)
                }
                switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
                }
            }, 
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        break;
                    case 'storage/canceled':
                        break;
                    case 'storage/unknown':
                        console.log(error.code)
                        break;
                }
                if ( parameter.error ){
                    parameter.error(error)
                }
            }, 
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    if ( parameter.success ){
                        parameter.success(downloadURL)
                    }
                    
                });
            }
        );
    }

    //顯示全畫面讀取
    LoadingStatus(status){
        if (status == 'show'){
            $('.full-seucce').css('display','flex')
        }else if (status == 'hide'){
            $('.full-seucce').css('display','none')
        }
    }

    /**********
     * 檢查物件是否不存在
     * obj : any object
     */
    isEmpty = (obj) => {
        if(
            typeof obj == 'undefined'
            || obj == null
            || obj.length<=0
        ){
            return true;
        }
        return false;
    }

    //初始化牙齒陣列
    initTeethAry(){
        let ary = {}
        for (let index = 18; index >= 11; --index) {
            ary[index] = {
                name:index.toString(),
                value:0,
                class:''
            }
        }
        for (let index = 21; index <= 28; index++) {
            ary[index] = {
                name:index.toString(),
                value:0,
                class:''
            }
        }
        for (let index = 38; index >= 31; --index) {
            ary[index] = {
                name:index.toString(),
                value:0,
                class:''
            }
        }
        for (let index = 41; index <= 48; index++) {
            ary[index] = {
                name:index.toString(),
                value:0,
                class:''
            }
        }
        return ary
    }
    //初始化日期格式
    initDataStr(dateObj){
        let DateTime = ''
        DateTime = dateObj.getFullYear() + '/' + ( dateObj.getMonth()+1).toString().padStart(2,'0') + '/' + dateObj.getDate().toString().padStart(2,'0')
        return DateTime
    }
    //呼叫顯示popup視窗
    initModal(target){
        const modal = new modals(document.getElementById(target));
        return modal
    }
    showModal(modal){
        modal.show();
    }
    //關閉popup視窗
    hideModal(modal){
        modal.hide();
    }
    //初始化狀態陣列
    orderOrderStatus(){
        
        var status = []
        status[0] = '訂單建立中'
        status[1] = '訂單開放媒合'
        status[2] = '訂單已媒合未確認'
        status[3] = '訂單齒模寄送'
        status[4] = '訂單等待技工所接受'
        status[5] = '訂單已確認'

        status[11] = '訂單執行中'
        status[12] = '訂單執行中'
        status[13] = '訂單製作中途確認'
        status[14] = '訂單執行中'
        status[15] = '訂單製作完成寄送中'
        status[16] = '訂單製作完成待診所確認'

        status[81] = '訂單執行中'
        status[82] = '訂單執行中'

        status[91] = '訂單技工所確認取消'
        status[98] = '訂單取消'
        status[99] = '訂單完成'

        status[100] = '媒合失敗'
        
        return status
    }
    //轉換狀態字串
    getOrderStatusString(index){
        let statusAry = this.orderOrderStatus()
        return statusAry[index]
    }
    getOrderSendTypeString(type){
        let srt = ''
        switch (type) { //1=親送 2=貨運 3=寄送
            case 1:
                srt = '親送'
                break;
            case 2:
                srt = '貨運'
                break;
            case 3:
                srt = '寄送'
                break;
            default:
                break;
        }
        return srt
    }
    //加密
    encryptionUserInfo(data){
        console.log(data)
        var key = CryptoJS.enc.Utf8.parse("c4e1u12d45e83f78535dkb86bah50f4a");
        var iv = CryptoJS.enc.Utf8.parse("");
        let encrypted = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(JSON.stringify(data)), 
            key, { 
                iv:iv,
                mode: CryptoJS.mode.ECB, 
                padding: CryptoJS.pad.Pkcs7
            }
        );
        console.log("encrypted=",encrypted.ciphertext.toString(CryptoJS.enc.Base64))
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
    }

    decryptionUserInfo(data){
        var key = CryptoJS.enc.Utf8.parse("c4e1u12d45e83f78535dkb86bah50f4a");
        var decrypt = CryptoJS.AES.decrypt(
            data, 
            key,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }
        );
        return JSON.parse( decrypt.toString( CryptoJS.enc.Utf8 ) )
    }

    getCompName(compType){ //取得title
        // console.log('getCompName=',compType)
        let titleName = ''
        switch ( compType ) {
            case 1:
                titleName = '管理後台'
                break;
            case 2:
                titleName = '掛號系統'
                break;
            case 3:
                titleName = '診所'
                break;
            case 4:
                titleName = '技工所'
                break;
            default:
                break;
        }
        // console.log('titleName=',titleName)
        return titleName
    }

    gotoSystemView(compID){
        let url = ''
        switch ( compID ) {
            case 1:
                url = 'http://localhost:3001?token='
                break;
            case 2:
                url = 'http://localhost:3002?token='
                break;
            case 3:
                url = 'http://localhost:3001?token='
                break;
            case 4:
                url = 'http://localhost:3003?token='
                break;
            default:
                break;
        }
        return url
    }

    //換算當地時間
    getLoaclDateTime(dateTime){
        if ( dateTime == null || dateTime == '' || dateTime == -1 ){
            return ""
        }
        let timeDate = new Date(dateTime)
        let loaclTime = new Date(timeDate)
        var sy =  loaclTime.getFullYear();   
        var sm = (loaclTime.getMonth()+1).toString().padStart(2,'0');
        var sd = loaclTime.getDate().toString().padStart(2,'0');
        var sh = (loaclTime.getHours()).toString().padStart(2,'0');
        var si = (loaclTime.getMinutes()).toString().padStart(2,'0');
        var ss = loaclTime.getSeconds().toString().padStart(2,'0');

        return sy+"-"+sm+"-"+sd+" "+sh+":"+si+":"+ss;
    }
    //換算當地時間
    getLoaclDate(dateTime){
        if ( dateTime == null || dateTime == '' || dateTime == -1 ){
            return ""
        }
        let timeDate = new Date(dateTime)
        let loaclTime = new Date(timeDate)
        var sy =  loaclTime.getFullYear();   
        var sm = (loaclTime.getMonth()+1).toString().padStart(2,'0');
        var sd = loaclTime.getDate().toString().padStart(2,'0');
        
        return sy+"-"+sm+"-"+sd
    }
    //所有的id管理
    getAccountTitle(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '先生'
                break;
            case 2:
                status_name = '小姐'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    
    getCompanyStatus(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '已啟用'
                break;
            case 2:
                status_name = '已停用'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getCompanyType(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '管理後台'
                break;
            case 2:
                status_name = '掛號系統商'
                break;
            case 3:
                status_name = '診所'
                break
            case 4:
                status_name = '技工所'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getAccountStatus(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '已啟用'
                break;
            case 2:
                status_name = '已停用'
                break;
            case 3:
                status_name = '等待啟用'
                break;
            case 4:
                status_name = '已逾時'
                break;
            case 5:
                status_name = '自行註冊'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getAccountJobTitle(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '醫師'
                break;
            case 2:
                status_name = '行政'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getOrderTypeStr(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '固定假牙'
                break;
            case 2:
                status_name = '活動假牙'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getRoleStatusStr(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '啟用'
                break;
            case 2:
                status_name = '停用'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }

    cutGroupTeeth(groupTeeth){ //分割連冠群組
        console.log('cutGroupTeeth')
        let Uteeth = [18,17,16,15,14,13,12,11,21,22,23,24,25,26,27,28] //初始化上顎排隊陣列
        let Lteeth = [38,37,36,35,34,33,32,31,41,42,43,44,45,46,47,48] //初始化下顎排隊陣列
        //處理上顎的部分
        let group = []
        let Uary = []
        for (let i = 0; i < Uteeth.length; i++) {
            // console.log(Uteeth[i],groupTeeth.indexOf(Uteeth[i].toString(),0))
            if ( groupTeeth.indexOf(Uteeth[i].toString(),0) > -1 ){
                Uary.push(Uteeth[i].toString())
            }else{
                if ( Uary.length > 0 ){
                    group.push(Uary)
                }
                Uary = []
            }
        }
        if ( Uary.length > 0 ){
            group.push(Uary)
        }
        //處理下顎部分
        let Lary = []
        for (let i = 0; i < Lteeth.length; i++) {
            // console.log(Lteeth[i],groupTeeth.indexOf(Lteeth[i].toString(),0))
            if ( groupTeeth.indexOf(Lteeth[i].toString(),0) > -1 ){
                Lary.push(Lteeth[i].toString())
            }else{
                if ( Lary.length > 0 ){
                    group.push(Lary)
                }
                Lary = []
            }
        }
        if ( Lary.length > 0 ){
            group.push(Lary)
        }
        return group
    }
    //設定往後推延日期
    initEndDate(sDate,maxDay){
        let dateTime = sDate
        let _d = dateTime.setDate(dateTime.getDate()+maxDay)
        return new Date(_d)
    }

    dateFormat(date){
        var _y = date.getFullYear().toString().padStart(4,'0');
        var _m = (date.getMonth()+1).toString().padStart(2,'0');
        var _d = date.getDate().toString().padStart(2,'0');
        // console.log('return=',_y+'-'+_m+'-'+_d)
        return _y+'-'+_m+'-'+_d;
    }

    dateTimeFormat(date){
        var _y = date.getFullYear().toString().padStart(4,'0');
        var _m = (date.getMonth()+1).toString().padStart(2,'0');
        var _d = date.getDate().toString().padStart(2,'0');
        var _H = date.getHours().toString().padStart(2,'0');
        var _i = date.getMinutes().toString().padStart(2,'0');
        var _s = date.getSeconds().toString().padStart(2,'0');
        // console.log('return=',_y+'-'+_m+'-'+_d)
        return _y+'-'+_m+'-'+_d+' '+_H+':'+_i+':'+_s;
    }

    dateTimeRomoveFormat(date){
        var _y = date.getFullYear().toString().padStart(4,'0');
        var _m = (date.getMonth()+1).toString().padStart(2,'0');
        var _d = date.getDate().toString().padStart(2,'0');
        return _y+'-'+_m+'-'+_d
    }

    initEndDate(sDate,maxDay){
        let dateTime = sDate
        let _d = dateTime.setDate(dateTime.getDate()+maxDay)
        return new Date(_d)
    }
}
export default shareds