<template>
    <div class="side-menu">
        <ul class="main-menu">
            <li v-for="(mainItem,i)  in mainMenu" :key="i">
                <h4 class="mb-0">{{mainItem.name}}</h4>
                <ul class="sub-menu" v-if="mainItem.childMenu">
                    <li v-for="(childItem,n)  in mainItem.childMenu" :key="n">
                        <router-link class="router-link" :to="childItem.to">
                            <i class="align-middle fas fa-fw fs-19" v-bind:class="[childItem.icon]"></i> <span class="align-middle">{{childItem.name}}</span>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'sideMenu',
        data() {
            return {
                mainMenu:[
                    {
                        name:'總覽',
                        childMenu:[
                            {
                                name:'儀表板',
                                to:'/',
                                icon:'fa-tachometer-alt'
                            }
                            
                        ]
                    },
                    {
                        name:'病患管理',
                        childMenu:[
                            {
                                name:'病患管理',
                                to:'/patient/list',
                                icon:'fa-address-book'
                            }
                        ]
                    },
                    {
                        name:'假牙訂單管理',
                        childMenu:[
                            {
                                name:'訂單管理',
                                to:'/denture-order/list',
                                icon:'fa-file-alt'
                            },
                            // {
                            //     name:'Redo',
                            //     to:'/denture-redo/list',
                            //     icon:'fa-redo-alt'
                            // }
                        ]
                    },
                    {
                        name:'媒合管理',
                        childMenu:[
                            {
                                name:'技工所管理',
                                to:'/dentlab/list',
                                icon:'fa-drafting-compass'
                            }
                        ]
                    },
                    {
                        name:'系統管理',
                        childMenu:[
                            {
                                name:'帳號管理',
                                to:'/account/list',
                                icon:'fa-users'
                            },
                            {
                                name:'公司資訊',
                                to:'/company/info',
                                icon:'fa-building'
                            },
                            // {
                            //     name:'刪除公司',
                            //     to:'/company/del',
                            //     icon:'fa-trash-alt'
                            // }
                        ]
                    }

                ]
            } 
        },
        props:[
            
        ],
        methods:{
            
        }
        
    }
</script>