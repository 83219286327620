const dentureOrderRoutes = [
    {
        path:'list',
        name:'denture-order-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/tables/list.vue')
    },
    {
        path:'add-denture-order',
        name:'add-denture-order',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/add/add.vue')
    },
    {
        path:'edit-denture-order',
        name:'edit-denture-order',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/edit/edit.vue')
    },
    {
        path:'denture-order-info',
        name:'denture-order-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/info.vue')
    },
    {
        path:'denture-order-con',
        name:'denture-order-con',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/con.vue')
    },
    {
        path:'edit-denture-order',
        name:'edit-denture-order',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/edit/edit.vue')
    },
    //下列為Demo用
    //選擇技工所
    {
        path:'denture-order-select-dentlab',
        name:'denture-order-select-dentlab',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/selectDentlab.vue')
    },
    //開放媒合設定
    {
        path:'denture-order-match-dentlab',
        name:'denture-order-match-dentlab',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/matchDentlab.vue')
    },
    //寄送實體齒模 Send solid tooth mold
    {
        path:'denture-order-send-tooth-modal',
        name:'denture-order-send-tooth-modal',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/sendToothModal.vue')
    },

]
export default dentureOrderRoutes