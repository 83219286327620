class subtitles {
    //物件建立時產生
    constructor(){
        // this.navData = {
        //     sub_tile:'儀表板',
        //     isBack:false,
        //     acvtion:[]
        // }
    }
    /*
    //設定navBar資訊
    data:傳入參數
    ary:原來的陣列
    return 將原本資料吐回去
    */
    setSubTitleObj(data,ary){
        ary.acvtion = []
        ary.isBack = false
        ary.isLink = null
        switch (data.name) {
            case 'dashboard':
              ary.sub_tile = '儀表板'
              break;
            case 'denture-order-list':
              ary.sub_tile = '訂單管理'
              ary.acvtion = [
                {
                  name:'建立固定假牙訂單',
                  icon:'fa-plus-circle',
                  to:'/denture-order/add-denture-order?type=0'
                },
                {
                  name:'建立活動假牙訂單',
                  icon:'fa-plus-circle',
                  to:'/denture-order/add-denture-order?type=1'
                }
              ]
              break;
            case 'denture-redo-list':
              ary.sub_tile = 'Redo'
              break;
            case 'add-denture-order':
              ary.sub_tile = '建立訂單'
              ary.isBack = true
              break;
            case 'denture-order-info':
              ary.sub_tile = '訂單詳細資訊'
              ary.isBack = false,
              ary.isLink = "/denture-order/list"
              break;
            case 'edit-denture-order':
              ary.sub_tile = '編輯訂單'
              ary.isBack = true
              break;
            case 'denture-order-select-dentlab':
              ary.sub_tile = '建立訂單'
              ary.isBack = true
              break;
            case 'denture-order-match-dentlab':
              ary.sub_tile = '建立訂單'
              ary.isBack = true
              break;
            case 'patient-list':
              ary.sub_tile = '病患管理'
              ary.acvtion = [
                {
                  name:'建立',
                  icon:'fa-plus-circle',
                  to:'/patient/add'
                },
              ]
              break;
            case 'patient-add':
              ary.sub_tile = '建立病人'
              ary.isBack = true
              break;
            case 'patient-info':
              ary.sub_tile = '病人資訊'
              ary.isBack = true
              break;
            case 'patient-edit':
              ary.sub_tile = '編輯病人'
              ary.isBack = true
              break;
            case 'patient-del':
              ary.sub_tile = '刪除病人'
              ary.isBack = true
              break;
            case 'dentlab-list':
              ary.sub_tile = '技工所管理'
              break;
            case 'dentlab-info':
              ary.sub_tile = '詳細資訊'
              ary.isBack = true
              break;
            case 'denture-order-con':
              ary.sub_tile = '檢視訂單'
              ary.isBack = true
              break;
            case 'account-list':
              ary.sub_tile = '帳號管理'
              ary.acvtion = [
                {
                  name:'建立',
                  icon:'fa-plus-circle',
                  to:'/account/add'
                }
              ]
              break;
            case 'account-add':
              ary.sub_tile = '建立帳號'
              ary.isBack = true
              break;
            case 'account-info':
              ary.sub_tile = '帳號詳細資訊'
              ary.isBack = true
              break;
            case 'account-edit':
              ary.sub_tile = '編輯帳號'
              ary.isBack = true
              break;
            case 'account-del':
              ary.sub_tile = '帳號詳細資訊'
              ary.isBack = true
              break;
            case 'company-info':
              ary.sub_tile = '公司資訊'
              ary.isBack = false
              ary.acvtion = [
                {
                  name:'編輯',
                  icon:'fa-pen-square',
                  to:'/company/edit'
                },
              ]
              break;
            case 'company-del':
              ary.sub_tile = '刪除公司'
              ary.isBack = false
              break;
            case 'company-edit':
              ary.sub_tile = '編輯公司'
              ary.isBack = false
              ary.isBack = true
              break;
            default:
              ary.sub_tile = 'App.vue 未設定'
              break;
          }
        return ary
    }
}
export default subtitles