const patientRoutes = [
    {
        path:'list',
        name:'patient-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/patient/list.vue')
    },
    {
        path:'add',
        name:'patient-add',
        component: () => import(/* webpackChunkName: "about" */ '../views/patient/add.vue')
    },
    {
        path:'info',
        name:'patient-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/patient/info.vue')
    },
    {
        path:'edit',
        name:'patient-edit',
        component: () => import(/* webpackChunkName: "about" */ '../views/patient/edit.vue')
    },
    {
        path:'del',
        name:'patient-del',
        component: () => import(/* webpackChunkName: "about" */ '../views/patient/del.vue')
    },

]
export default patientRoutes