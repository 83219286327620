import dentureOrderRoutes from './dentureOrder'
import patientRoutes from './patient'
import dentlabRoutes from './dentlabRoutes'
import accountRoutes from './accountRoutes'
import companyRoutes from './companyRoutes'
const routes = [
    {
        path:'/',
        name:'dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index.vue')
    },
    {
        path:'/patient',
        name:'patient',
        children:patientRoutes
    },
    {
        path:'/denture-order',
        name:'denture-order',
        children:dentureOrderRoutes
    },
    {
        path:'/denture-redo',
        name:'denture-redo',
        children:[
            {
                path:'list',
                name:'denture-redo-list',
                component: () => import(/* webpackChunkName: "about" */ '../views/dentureRedo/list.vue')
            }
        ]
    },
    {
        path:'/dentlab',
        name:'dentlab',
        children:dentlabRoutes
    },
    {
        path:'/account',
        name:'account',
        children:accountRoutes
    },
    {
        path:'/company',
        name:'company',
        children:companyRoutes
    },
    {
        path:'/his',
        name:'his',
        children:[
            {
                path:'login',
                name:'his-login',
                component: () => import(/* webpackChunkName: "about" */ '../views/his/login.vue')
            },
        ]
    },
]
export default routes